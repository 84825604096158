import { Button } from '@mui/material'
import Link from 'next/link'
import { ROUTES } from '../../utils/routes'
import { useTranslation } from '../../utils/i18n'

export function SingInButton({ onClick }) {
  const { t } = useTranslation('common', { keyPrefix: 'pop_up' })
  return (
    <Link href={ROUTES.login} passHref>
      <Button
        autoFocus
        style={{ color: 'var(--sm-color-light)', marginLeft: 10 }}
        variant="contained"
        onClick={onClick}
        component="a"
      >
        {t('sign_in')}
      </Button>
    </Link>
  )
}
