import { createContext, useContext, useMemo, useState } from 'react'

export const MIN = 1.01
export const MAX = 5
export const MEDIUM = 2.5

export const ODDS_STEPS = [
  { value: MIN, label: MIN },
  { value: 2, label: 2 },
  { value: MEDIUM, label: MEDIUM },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: MAX, label: `${MAX}+` }
]

const DEFAULT = [MIN, MEDIUM]
const initial = () => ({
  home: { value: DEFAULT, isDefault: true },
  away: { value: DEFAULT, isDefault: true }
})
const OddsContext = createContext([initial(), () => {}])

export function useOddsFilter() {
  return useContext(OddsContext)
}

export function OddsProvider({ children }) {
  const [odds, setOdds] = useState(initial())

  const set = ({ home = DEFAULT, away = DEFAULT }) => {
    setOdds({
      home: {
        value: home,
        isDefault: home.every((odd, index) => odd === DEFAULT[index])
      },
      away: {
        value: away,
        isDefault: away.every((odd, index) => odd === DEFAULT[index])
      }
    })
  }

  const value = useMemo(() => [odds, set], [odds])

  return <OddsContext.Provider value={value}>{children}</OddsContext.Provider>
}
