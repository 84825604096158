import { useState } from 'react'
import { useRouter } from 'next/router'
import { Button, Menu, MenuItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Link from 'next/link'

import { locales } from './locales'

const Country = ({ locale, isSelected, asPath, onClick }) => {
  const { code, label } = locale

  return (
    <Link href={asPath} locale={code} passHref prefetch={false}>
      <MenuItem onClick={onClick} component="a">
        <span>{label}</span>
        {isSelected && (
          <CheckIcon
            sx={{
              'margin-left': 'auto',
              color: 'var(--sm-primary-color)'
            }}
          />
        )}
      </MenuItem>
    </Link>
  )
}

export default function LanguageSelection() {
  const { locale, asPath } = useRouter()
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        sx={{ mr: 1 }}
      >
        {locale}
        <KeyboardArrowDownIcon sx={{ ml: 1 }} />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ top: 5 }}
      >
        {locales.map(_locale => (
          <Country
            key={_locale.label}
            locale={_locale}
            isSelected={_locale.code === locale}
            asPath={asPath}
            onClick={handleClose}
          />
        ))}
      </Menu>
    </div>
  )
}
