import Head from 'next/head'
import { useRouter } from 'next/router'
import { UserProvider } from '@auth0/nextjs-auth0'

import Layout from '../components/Layout'
import { I18nProvider } from '../utils/i18n'

function Meta() {
  const { asPath, locale } = useRouter()
  const url = new URL(
    `https://www.smartscore365.com${
      locale === 'en' ? '' : `/${locale}`
    }${asPath}`
  )

  const en = `https://www.smartscore365.com${asPath}`
  const uk = `https://www.smartscore365.com/uk${asPath}`

  return (
    <Head>
      <meta property="og:url" content={`${url.origin}${url.pathname}`} />
      {/* eslint-disable-next-line react/no-unknown-property*/}
      <link rel="alternate" hreflang="uk-ua" href={uk} />
      {/* eslint-disable-next-line react/no-unknown-property*/}
      <link rel="alternate" hreflang="en-gb" href={en} />
      {/* eslint-disable-next-line react/no-unknown-property*/}
      <link rel="alternate" href={en} hreflang="x-default" />
    </Head>
  )
}

export default function App({ Component, pageProps }) {
  return (
    <I18nProvider
      locale={pageProps._nextI18Next?.initialLocale}
      lngDict={pageProps._nextI18Next?.initialI18nStore}
    >
      <Meta />
      <UserProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </UserProvider>
    </I18nProvider>
  )
}
