import { createContext, useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { api } from '../../../utils/api'
import { CacheConstants } from '../../shared/constants'

const LeaguesFiltersContext = createContext({
  leagues: [],
  data: []
})

async function fetchData() {
  const data = await api('leagues', {
    searchParams: {
      current: true
    }
  }).json()

  return data
}

export function LeaguesFiltersProvider({ children }) {
  const [leagues, setLeagues] = useState([])
  const { data = [], isFetched } = useQuery(
    ['leagues/all'],
    () => fetchData(),
    {
      notifyOnChangeProps: ['isFetched'],
      staleTime: CacheConstants.ONE_HOUR
    }
  )

  function onSelectLeague(leagues) {
    setLeagues(leagues)
  }

  const value = useMemo(
    () => ({
      leagues,
      onSelectLeague,
      data
    }),
    // eslint-disable-next-line
    [leagues, isFetched]
  )

  return (
    <LeaguesFiltersContext.Provider value={value}>
      {children}
    </LeaguesFiltersContext.Provider>
  )
}

export function useLeaguesFilters() {
  return useContext(LeaguesFiltersContext)
}
