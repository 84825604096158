import { createContext, useContext, useMemo, useState } from 'react'

export const MIN = 0
export const MEDIUM = 50
export const MAX = 100

export const FORMS = [
  { value: MIN, label: `${MIN}%` },
  { value: 25, label: `25%` },
  { value: MEDIUM, label: `${MEDIUM}%` },
  { value: 75, label: '75%' },
  { value: MAX, label: `${MAX}%` }
]

const DEFAULT = [MIN, MEDIUM]
const initial = () => ({
  home: { value: DEFAULT, isDefault: true },
  away: { value: DEFAULT, isDefault: true }
})
const FormsContext = createContext([initial(), () => {}])

export function useFormsFilter() {
  return useContext(FormsContext)
}

export function FormsProvider({ children }) {
  const [forms, setForms] = useState(initial())

  const set = ({ home = DEFAULT, away = DEFAULT }) => {
    setForms({
      home: {
        value: home,
        isDefault: home.every((form, index) => form === DEFAULT[index])
      },
      away: {
        value: away,
        isDefault: away.every((form, index) => form === DEFAULT[index])
      }
    })
  }

  const value = useMemo(() => [forms, set], [forms])

  return <FormsContext.Provider value={value}>{children}</FormsContext.Provider>
}
