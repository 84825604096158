export const ROUTES = {
  home: '/',
  logout: '/api/auth/logout',
  login: '/api/auth/login',
  profile: '/profile',
  match: '/match/',
  team: '/teams/',
  about: '/about',
  league: '/league/',
  leagues: '/leagues',
  contact: '/contact',
  responsibleGambling: '/responsible-gambling',
  terms: '/terms-of-use',
  privacy: '/privacy-policy'
}
