import { useState, Fragment } from 'react'
import Link from 'next/link'
import {
  Toolbar,
  Button,
  Typography,
  IconButton,
  Drawer,
  Grid,
  AppBar
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
// import SettingsBrightness from '@mui/icons-material/SettingsBrightness'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Close from '@mui/icons-material/Close'
// import AccountCircle from '@mui/icons-material/AccountCircle'
import { useRouter } from 'next/router'
import { useTranslation } from '../../utils/i18n'

import { ROUTES } from '../../utils/routes'
import { useAuth } from '../auth/hooks'
import s from './header.module.css'
import LanguageSelection from './languageSelection/LanguageSelection'
import { Box } from '@mui/system'

const BetaLabel = (
  <Box
    sx={{
      textTransform: 'uppercase',
      padding: '4px',
      fontSize: 12,
      mt: '-6px',
      color: 'var(--sm-primary-color)'
    }}
  >
    beta
  </Box>
)

const Logo = (
  <Typography variant="h6" component="span" sx={{ display: 'flex' }}>
    <span style={{ color: 'var(--sm-header-bg-color)' }}>SmartScore</span>
    &nbsp;365
    {BetaLabel}
  </Typography>
)

const logoContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  whiteSpace: 'nowrap',
  ':visited, :link': {
    color: 'white'
  },
  span: {
    color: 'var(--sm-primary-color)'
  },
  '@media (max-width: 400px)': {
    fontSize: '16px'
  }
}

const links = [
  { label: 'matches', href: ROUTES.home },
  { label: 'leagues', href: ROUTES.leagues },
  { label: 'about', href: ROUTES.about }
]

function DrawerSection({ onDrawerClose, open }) {
  const { t } = useTranslation('common', { keyPrefix: 'header' })

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onDrawerClose}
      classes={{ paper: s.drawerPaper }}
    >
      <Grid
        direction="column"
        style={{
          borderBottom: '1px solid #dadce0',
          minWidth: '80%'
        }}
        container
        component="nav"
      >
        <Grid
          sx={{
            ...logoContainerStyles,
            padding: '16px 16px 8px',
            borderBottom: '1px solid #dadce0'
          }}
        >
          <IconButton
            style={{ marginLeft: -7 }}
            size="large"
            onClick={onDrawerClose}
          >
            <Close />
          </IconButton>
          {Logo}
        </Grid>
        {links.map(link => (
          <Link key={link.label} href={link.href} prefetch={false}>
            <a className={s['nav-link']} onClick={onDrawerClose}>
              {t(link.label)}
            </a>
          </Link>
        ))}
        {/** TODO: Add logout */}
      </Grid>
      <section className={s['auth-block-mobile']} suppressHydrationWarning>
        <div onClick={onDrawerClose}>
          <AuthBlock />
        </div>
      </section>
    </Drawer>
  )
}

function HeaderBar() {
  const { t } = useTranslation('common', { keyPrefix: 'header' })
  const [open, setOpen] = useState(false)
  const onDrawerOpen = () => setOpen(true)
  const onDrawerClose = () => setOpen(false)
  const router = useRouter()

  // eslint-disable-next-line no-unused-vars
  const { slug, ...rest } = router.query

  return (
    <AppBar
      sx={{
        backgroundColor: 'var(--sm-header-bg-color)',
        padding: '0',
        contain: 'layout'
      }}
      position="sticky"
    >
      <DrawerSection open={open} onDrawerClose={onDrawerClose} />

      <Toolbar style={{ alignItems: 'initial' }}>
        <IconButton
          className={s['menu-icon']}
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          size="large"
        >
          <MenuIcon color="primary" />
        </IconButton>

        {/* Logo */}
        <Link
          href={{
            pathname: ROUTES.home,
            query: { ...rest }
          }}
          prefetch={false}
          passHref
        >
          <Typography variant="h6" sx={logoContainerStyles} component="a">
            SmartScore&nbsp;<span>365</span>
            {BetaLabel}
          </Typography>
        </Link>

        {/* Navigation */}
        <nav className={s.navigation}>
          <Link
            href={{
              pathname: ROUTES.home,
              query: { ...rest }
            }}
            passHref
            prefetch={false}
          >
            <Button
              className={
                ROUTES.home === router.pathname ||
                router.pathname.includes(ROUTES.match)
                  ? s.active
                  : ''
              }
            >
              {t('matches')}
            </Button>
          </Link>

          <Link href={ROUTES.about} passHref prefetch={false}>
            <Button
              className={router.pathname === ROUTES.about ? s.active : ''}
            >
              {t('about')}
            </Button>
          </Link>
        </nav>

        {/* Search */}

        {/* Auth */}
        {/* {!sessionState && ( */}
        <section className={s.btns}>
          <LanguageSelection />
          <section className={s['auth-block']} suppressHydrationWarning>
            <AuthBlock />
          </section>
        </section>
        {/* )} */}
      </Toolbar>
    </AppBar>
  )
}

const AuthBlock = () => {
  const { t } = useTranslation('common', { keyPrefix: 'header' })
  const { user, isLoading } = useAuth()

  if (isLoading) return null

  /* <Button style={{ marginRight: 8 }} color="primary">
  <SettingsBrightness />
</Button> */

  return user ? (
    <>
      {/* <Link href={ROUTES.profile} passHref>
      <Button className={s.profile}>
        <AccountCircle color="primary" />
        Profile
      </Button>
    </Link> */}

      <Link href={ROUTES.logout} passHref prefetch={false}>
        <Button color="primary" className={s.cta}>
          <ExitToApp color="primary" />
          <p style={{ marginLeft: 4 }}>{t('logout')}</p>
        </Button>
      </Link>
    </>
  ) : (
    <Link href={ROUTES.login} passHref prefetch={false}>
      <Button variant="outlined" className={s.cta} color="primary">
        <ExitToApp />
        <p style={{ marginLeft: 4 }}>{t('login')}</p>
      </Button>
    </Link>
  )
}

export default HeaderBar
