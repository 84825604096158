import classNames from './socialLinks.module.css'

import facebook from '../../../../public/static/about/facebook.svg'
import twitter from '../../../../public/static/about/twitter.svg'

const socialLinks = [
  // { label: '', href: './', icon: '/static/about/mask-group-6.svg' },
  // { label: '', href: './', icon: '/static/about/mask-group-7.svg' },
  {
    label: 'facebook',
    href: 'https://www.facebook.com/profile.php?id=100073755330655',
    icon: facebook.src
  },
  {
    label: 'twitter',
    href: 'https://mobile.twitter.com/Smartscore365',
    icon: twitter.src
  }
]

function SocialLinks() {
  return (
    <div className={classNames['social-links']}>
      {socialLinks.map((link, index) => (
        <a
          key={index}
          href={link.href}
          rel="noreferrer"
          target="_blank"
          className={classNames['social-link']}
        >
          <img
            src={link.icon}
            alt={link.label}
            width={30}
            height={30}
            loading="lazy"
          />
        </a>
      ))}
    </div>
  )
}

export default SocialLinks
