import { useState, useEffect } from 'react'
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useRouter } from 'next/router'
import { ROUTES } from '../../utils/routes'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import StarIcon from '@mui/icons-material/Star'
import classNames from './navigation.module.css'
import { useTranslation } from '../../utils/i18n'

const LIVE = 1

// change the index from the router to the index button
const TAB_MAP = {
  0: 0,
  1: 1,
  4: 2,
  leagues: 3
}

const buttons = [
  { label: 'matches', icon: <SportsSoccerIcon />, href: '/?tab=0' },
  { label: 'live', icon: <LiveTvIcon />, href: '/' },
  { label: 'favorites', icon: <StarIcon />, href: '/?tab=4' },
  { label: 'leagues', icon: <EmojiEventsIcon />, href: ROUTES.leagues }
]

export default function BottomNavigationMenu() {
  const { query, pathname, push } = useRouter()
  const { t } = useTranslation('common', { keyPrefix: 'bottom_navigation' })
  const tab =
    pathname === ROUTES.home
      ? query.tab ?? LIVE
      : pathname === ROUTES.leagues
      ? 'leagues'
      : null
  const [localTab, setTab] = useState(tab)

  useEffect(() => {
    if (tab !== localTab) setTab(tab)
  }, [tab])

  return (
    <Paper elevation={3} className={classNames.menu}>
      <BottomNavigation showLabels value={TAB_MAP[localTab]}>
        {buttons.map((button, index) => (
          <BottomNavigationAction
            key={index}
            onClick={() => push(button.href)}
            label={t(button.label)}
            icon={button.icon}
            component="a"
          />
        ))}
      </BottomNavigation>
    </Paper>
  )
}
