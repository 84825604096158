import Link from 'next/link'
import { useTranslation } from '../../utils/i18n'
import classNames from './footer.module.css'
import { ROUTES } from '../../utils/routes'
import SocialLinks from './components/SocialLinks'

const links = [
  { label: 'terms_of_use', href: ROUTES.terms },
  { label: 'privacy_policy', href: ROUTES.privacy },
  {
    label: 'responsible_gambling',
    href: ROUTES.responsibleGambling
  },
  { label: 'contact', href: ROUTES.contact }
]

export default function Footer() {
  const { t } = useTranslation('common', { keyPrefix: 'footer' })
  return (
    <footer className={classNames.footer}>
      <div className={classNames.wrapper}>
        <div className={classNames.container}>
          <SocialLinks />

          <div className={classNames['links']}>
            {links.map((link, index) => (
              <div key={index} className={classNames['link']}>
                <Link href={link.href} prefetch={false} passHref>
                  <a className={classNames.linkLabel}>
                    {t(`links.${link.label}`)}
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className={classNames.rules}>
          <p>© 2022 SmartScore365 {t('labels.rights')}</p>
          <p>{t('labels.gamble_responsibly')}</p>
        </div>
      </div>
    </footer>
  )
}
